import { assignIdToComponent } from '@/js/modules/account/management/company/utils/FigurePrintLayoutUtils';
import { getUid } from 'ol';
import Api, { layer as LayerApi, sample as SampleApi } from '../api';
import { BLACK } from '../business-logic/color';
import {
  OTHER_CHEMICAL_GROUP_ID,
  getChemicalGroupsOfCompany,
  getChemicalGroupsOfLab,
  getChemicalGroupsOfProject,
  getSampleChemicalsOfGroup,
  type ChemicalGroup,
  type OtherChemicalGroup,
  type SampleChemical,
} from '../business-logic/evalu8';
import type { UpdateFigureSettingsPayload } from '../business-logic/figure';
import { LAYER_TYPES, LAYER_USAGES } from '../business-logic/layer';
import { getScopedSamples } from '../business-logic/sample';
import type { FigureStylingRule } from '../business-logic/styling/figure-styling-rule';
import {
  FigureStylingRuleLocator,
  UpdateOneOfFigureStylingRulesPayload,
} from '../business-logic/styling/figure-styling-rule';
import { BasemapId } from '../lib/olbm/layer/basemap/types';
import {
  Integration,
  IntegrationId,
} from '../lib/olbm/layer/service/integration';
import TreeView from '../modules/TreeView';
import * as openlayers from '../modules/openlayers';
import type {
  StreamingParameters,
  Sample,
  FigureSample,
} from '../api/sample/definitions';

declare const axios: any;

export function resetMapsState(context) {
  context.commit('resetMapsState');
}

export function setShapeProperty(context, data) {
  context.commit('setShapeProperty', data);
}

export function resetMapLoadingStatuses(context) {
  context.commit('setIsLoadingLayers', false);
  context.commit('setIsLoadingSamples', false);
  context.commit('setIsLoadingFigures', false);
  context.commit('setHasLoadedMapData', false);
}

/**
 * Layers
 */
export function reorderAllLayers(context, allLayers) {
  context.commit('reorderAllLayers', allLayers);
}

export function addNewLayer(context, layer) {
  context.commit('addNewLayer', layer);
}

export function removeLayer(context, layer_id) {
  context.commit('removeLayer', layer_id);
}

export function updateIndividualLayer(context, layer) {
  context.commit('updateIndividualLayer', layer);
}

export async function initAllLayers(context) {
  context.commit('setIsLoadingLayers', true);

  try {
    const response = await Api.getListOfLayers();
    context.commit('initAllLayers', response.data);
  } finally {
    context.commit('setIsLoadingLayers', false);
  }
}

export async function initAllFigureLayers(context, figureId: number) {
  context.commit('setIsLoadingLayers', true);

  try {
    const response = await LayerApi.getListOfFigureLayers(figureId);
    context.commit('initAllFigureLayers', response.data);
  } finally {
    context.commit('setIsLoadingLayers', false);
  }
}

export function setCurrentShapeProperties(context, properties) {
  context.commit('setCurrentShapeProperties', properties);
}

export function resetCurrentShapeProperties(context) {
  context.commit('resetCurrentShapeProperties');
}

export function setIsUpdatingLayer(context, status) {
  context.commit('setIsUpdatingLayer', status);
}

export function setPreviousSampleProperties(context, properties) {
  context.commit('setPreviousSampleProperties', properties);
}

export function setEsriToken(context, token) {
  context.commit('setEsriToken', token);
}

export async function loadIntegrations(context) {
  const ids: IntegrationId[] = [];
  const { basemapApis } = context.getters;
  if (
    basemapApis.some((basemapApi) => basemapApi.index === BasemapId.NEARMAP)
  ) {
    ids.push(IntegrationId.NEARMAP);
  }
  if (
    basemapApis.some((basemapApi) => basemapApi.index === BasemapId.METROMAP)
  ) {
    ids.push(IntegrationId.METROMAP);
  }

  const integrations: Integration[] = [];
  for (const id of ids) {
    try {
      const {
        data: {
          data: {
            integration: { access_token: apiKey },
          },
        },
      } = await axios.get(`/company/external-service/${id}`);
      integrations.push({ id, apiKey: apiKey ?? null });
    } catch (e) {
      console.error('External integration might be disabled', e);
      integrations.push({
        id,
        apiKey: null,
      });
    }
  }

  context.commit('setIntegrations', integrations);
}

export function updateLayerTextById(context, data) {
  context.commit('updateLayerTextById', data);
}

export function clearAllSamples(context) {
  context.commit('clearAllSamples');
}

export function addNewSample(context, sample) {
  context.commit('addNewSample', sample);
}

export async function deleteSampleById(context, { id, forced = false }) {
  await context.dispatch('deleteSamplesById', { ids: [id], forced });
}

export async function deleteSamplesById(context, { ids, forced = false }) {
  const { allSamples } = context.state;

  try {
    await context.dispatch('setIsUpdatingLayer', true);
    context.commit('setSampleIdsBeingDeleted', ids);

    for (let i = 0; i < ids.length; i++) {
      const id = ids[i];
      const index = allSamples.findIndex((item) => item.id === id);
      if (index === -1) {
        throw `The sample with id: ${id} does not exist.`;
      }

      const sample = allSamples[index];
      const { sample_group: sampleGroup } = sample;

      // don't delete sample if has results, just clear lat / lng
      const {
        data: { is_deletion_allowed: isDeletionAllowed },
      } = await Api.getSampleRestrictions(id);
      if (!isDeletionAllowed) {
        const nextSample = {
          ...sample,
          latitude: null,
          longitude: null,
          // set back to default sample group
          sample_group: context.getters.getSampleGroupByIdentifier(),
        };

        context.dispatch('replaceSample', nextSample);
      } else {
        context.commit('deleteSampleByIndex', index);
      }

      await Api.deleteSampleById({ id, forced });

      if (
        sampleGroup.id &&
        !sampleGroup.data.properties.default &&
        !getScopedSamples(sampleGroup, allSamples).length
      ) {
        await Api.deleteLayer({
          id: sampleGroup.id,
        });

        if (context.state.selectedSampleScopeId === sampleGroup.id) {
          await context.dispatch('setSelectedSampleScopeId', null);
        }

        await context.dispatch('removeLayer', sampleGroup.id);
      }
    }
  } finally {
    context.commit('setSampleIdsBeingDeleted', []);
    await context.dispatch('setIsUpdatingLayer', false);
  }
}

export function replaceSample(context, nextSample) {
  context.commit('replaceSample', nextSample);
}

export function buildSampleGroupTree(context, shouldSkipSampleNodes = true) {
  context.commit('buildSampleGroupTree', shouldSkipSampleNodes);
}

export async function streamSamples(
  context,
  parameters: StreamingParameters<Sample>
) {
  await context.dispatch('setIsLoadingSamples', true);
  try {
    await SampleApi.streamSamples(
      parameters,
      (id) => {
        return context.getters.getSampleGroupById(id);
      },
      (sample) => {
        context.dispatch('addNewSample', sample);
      }
    );
  } finally {
    await context.dispatch('setIsLoadingSamples', false);
  }
}

export async function streamFigureSamples(
  context,
  parameters: StreamingParameters<FigureSample>
) {
  await context.dispatch('setIsLoadingSamples', true);
  try {
    await SampleApi.streamFigureSamples(parameters, (figureSample) => {
      const { id } = figureSample;
      const sample = context.getters.getSampleById(id);
      context.dispatch('replaceSample', {
        ...sample,
        ...figureSample,
      });
    });
  } finally {
    await context.dispatch('setIsLoadingSamples', false);
  }
}

/**
 * Figures
 */
export function setFigureIdToSelect(context, figure_id) {
  context.commit('setFigureIdToSelect', figure_id);
}

export function initAllFigures(context, data) {
  context.commit('setIsLoadingFigures', true);
  return Api.getListOfFigures(data)
    .then((response) => {
      context.commit('initAllFigures', response.data);
      context.commit('initGlobalSettings', response.data.global_settings);

      const {
        figure_styling_rules: figureStylingRules,
        template_tab_styling_rules: templateTabStylingRules,
      } = response.data;
      // Styling rules defined in Maps take priority.
      context.commit('setFigureStylingRules', [
        ...templateTabStylingRules,
        ...figureStylingRules,
      ]);
    })
    .finally(() => {
      context.commit('setIsLoadingFigures', false);
    });
}

export function setIsLoadingLayers(context, value) {
  context.commit('setIsLoadingLayers', value);
}

export function selectFigure(context, figure) {
  context.commit('selectFigure', figure);
}

export function modifyFigure(context, figure) {
  context.commit('modifyFigure', figure);
}

export function modifyFigureColumn(context, data) {
  context.commit('modifyFigureColumn', data);
}

export function deleteFigure(context, figure_id) {
  context.commit('deleteFigure', figure_id);
}

export function modifyFigureLayer(context, data) {
  context.commit('modifyFigureLayer', data);
}

export function modifyGlobalSettings(context, settings) {
  context.commit('modifyGlobalSettings', settings);
}

/**
 * Additional Data
 */
export function initAllScenarioStyles(context) {
  if (context.state.allScenarios.length > 0) {
    return;
  }

  return Api.getListOfScenarioStyles().then((response) => {
    context.commit('initAllScenarios', response.data.scenarios);
    context.commit('initAllScenarioStyles', response.data.styles);
    context.commit('initScenarioSet', response.data.scenario_set);
  });
}

function preprocessChemicalGroups(
  chemicalGroups: ChemicalGroup[],
  projectId: number,
  allChemicals: SampleChemical[]
): (ChemicalGroup | OtherChemicalGroup)[] {
  const groupsOfCompany = getChemicalGroupsOfCompany(chemicalGroups);
  const groupsOfProject = getChemicalGroupsOfProject(chemicalGroups, projectId);
  const groupsOfLab = getChemicalGroupsOfLab(chemicalGroups);
  const filteredChemicalGroups = [
    ...groupsOfCompany,
    ...groupsOfProject,
    ...groupsOfLab,
  ];
  const otherGroup: OtherChemicalGroup = {
    id: OTHER_CHEMICAL_GROUP_ID,
    group_title: 'Other',
  };
  return [...filteredChemicalGroups, otherGroup].filter(
    (cg) =>
      getSampleChemicalsOfGroup(cg, allChemicals, filteredChemicalGroups)
        .length > 0
  );
}

export async function initAllChemicals(context) {
  if (context.state.allChemicals.length > 0) {
    return;
  }

  try {
    const {
      data: { chemicals: allChemicals, chemical_groups: chemicalGroups },
    } = await Api.getListOfChemicals();
    context.commit('initAllChemicals', {
      allChemicals,
      allChemicalGroups: preprocessChemicalGroups(
        chemicalGroups,
        context.rootState.project.project_id,
        allChemicals
      ),
    });
  } catch {
    context.commit('initAllChemicals', {
      allChemicals: [],
      allChemicalGroups: [],
    });
  }
}

export function initAllExceedances(context) {
  if (context.state.allExceedances.length > 0) {
    return;
  }

  return Api.getListOfExceedances()
    .then((response) => {
      context.commit('initAllExceedances', response.data.exceedances);
    })
    .catch(() => {
      context.commit('initAllExceedances', []);
    });
}

export function initAllChemicalResults(context, data) {
  return Api.getListOfChemicalResults(data.params)
    .then((response) => {
      context.commit('initAllChemicalResults', response.data.chemicals);
    })
    .catch((error) => {
      context.commit('initAllChemicalResults', []);
      console.error(
        error.response?.data?.message ??
          'Something went wrong while loading chemical results.'
      );
    });
}

export function setBasemapRevisionDate(context, date) {
  context.commit('setBasemapRevisionDate', date);
}

export function setHighlightedLayerId(context, layer) {
  context.commit('setHighlightedLayerId', layer);
}

export function setHasLoadedMapData(context, value) {
  context.commit('setHasLoadedMapData', value);
}

export function updateClientLayerOrdering(context, data) {
  context.commit('updateClientLayerOrdering', data);
}

export function setSelectedLayer(context, data) {
  context.commit('setSelectedLayer', data);
}

export function setSelectedServiceLayerFeature(context, data) {
  context.commit('setSelectedServiceLayerFeature', data);
}

export async function loadFigurePrintLayouts(context, companyId) {
  const { data: figurePrintLayouts } = await axios.get(
    `/company/${companyId}/figure-print-layouts`
  );
  for (const item of figurePrintLayouts) {
    const { components } = item;
    item.components = components.map((item2) => assignIdToComponent(item2));
  }
  context.commit('setFigurePrintLayouts', figurePrintLayouts);
}

export async function createOrUpdateBuiltinCalloutDelegate(
  context,
  { map, mapBuiltinCallout }
) {
  let delegate = context.getters.builtinCalloutDelegate;
  const isNew = !delegate;
  const viewer = map.getViewer();
  const geojson = mapBuiltinCallout.toGeoJSON();
  geojson.properties = viewer.getScaledLayerProperties(
    geojson.properties,
    true
  );
  const {
    data: { layer },
  } = await Api.modifyLayer({
    layer_id: delegate?.id,
    layer: geojson,
    figure_id: context.state.selectedFigure.id,
  });
  delegate = layer;

  delegate.data = TreeView.convertLayerData(
    delegate,
    getUid(mapBuiltinCallout) as any
  );
  mapBuiltinCallout.databaseLayerId = delegate.id;

  if (isNew) {
    await context.dispatch('addNewLayer', delegate);
  } else {
    await context.dispatch('updateIndividualLayer', delegate);
  }
  return delegate;
}

export function setImageEditMethodCode(context, value) {
  context.commit('setImageEditMethodCode', value);
}

export function resetImageEditMethodCode(context) {
  context.dispatch(
    'setImageEditMethodCode',
    openlayers.interactions.image.constants.EDIT_METHOD_CODES.MOVE_RESIZE_ROTATE
  );
}

export function setAmfSessionCancelChoice(context, value) {
  context.commit('setAmfSessionCancelChoice', value);
}

export function setAddMultipleFeaturesEnabled(context, value) {
  context.commit('setAddMultipleFeaturesEnabled', value);
}

export function setConfirmData(context, value) {
  context.commit('setConfirmData', value);
}

export function clearAmfSessionFeatureIds(context) {
  context.commit('clearAmfSessionFeatureIds');
}

export function addAmfSessionFeatureId(context, value) {
  context.commit('addAmfSessionFeatureId', value);
}

export function setServiceLayerVisibleExtentOption(context, value) {
  context.commit('setServiceLayerVisibleExtentOption', value);
}

export function setIsAddingFeature(context, value) {
  context.commit('setIsAddingFeature', value);
}

export async function createLayerNodesForFeatureCollection(
  context,
  gFeatureCollection
) {
  let result;
  const { name, fileName } = gFeatureCollection;

  let { features } = gFeatureCollection;
  features = features.map((f) => ({ ...f, properties: {} }));
  const isSingleFeature = features.length === 1;

  const {
    data: { layer: folderLayerNode },
  } = await Api.modifyLayer({
    layer_id: null,
    layer: {
      properties: { type: 'folder', title: name || fileName },
    },
    figure_id: context.state.selectedFigure.id,
  });
  result = folderLayerNode;
  result.data = TreeView.convertLayerData(folderLayerNode);
  result.children = [];

  const geometryType = features[0].geometry.type;
  let properties;
  if (['LineString', 'MultiLineString'].includes(geometryType)) {
    const commonProperties = {
      outlineStyle: 0,
      fillStyle: 0,
      fillOpacity: 0.3,
      color: BLACK,
    };
    properties = isSingleFeature
      ? {
          ...commonProperties,
          type: LAYER_TYPES.POLYLINE,
          title: 'Untitled Line',
        }
      : {
          ...commonProperties,
          type: LAYER_TYPES.FEATURE_COLLECTION,
          usage: LAYER_USAGES.BASEMAP_POLYLINES,
          title: 'Untitled Lines',
        };
  } else if (['Polygon', 'MultiPolygon'].includes(geometryType)) {
    const commonProperties = {
      outlineStyle: 0,
      fillStyle: 0,
      fillOpacity: 0.3,
      color: '#2980b9',
    };
    properties = isSingleFeature
      ? {
          ...commonProperties,
          type: LAYER_TYPES.POLYGON,
          title: 'Untitled Polygon',
        }
      : {
          ...commonProperties,
          type: LAYER_TYPES.FEATURE_COLLECTION,
          usage: LAYER_USAGES.BASEMAP_POLYGONS,
          title: 'Untitled Polygons',
        };
  } else {
    throw `The ${geometryType} is not supported.`;
  }

  const {
    data: { layer: featureLayerNode },
  } = await Api.modifyLayer({
    layer_id: null,
    parent_id: folderLayerNode.id,
    layer: isSingleFeature
      ? { ...features[0], properties }
      : { type: 'FeatureCollection', features, properties },
    figure_id: context.state.selectedFigure.id,
  });
  featureLayerNode.data = TreeView.convertLayerData(featureLayerNode);
  result.children.push(featureLayerNode);

  return result;
}

export function setSelectedSampleScopeId(context, value) {
  context.commit('setSelectedSampleScopeId', value);
}

export function setSampleBeingRenamed(context, value) {
  context.commit('setSampleBeingRenamed', value);
}

export async function renameSample(context, { sample, nextTitle }) {
  await context.dispatch('setIsUpdatingLayer', true);

  try {
    await Api.modifySample({
      id: sample.id,
      custom_title: nextTitle,
      matrix: context.rootState.matrix,
    });

    const nextSample = {
      ...sample,
      custom_title: nextTitle,
    };
    await context.dispatch('replaceSample', nextSample);

    return nextSample;
  } finally {
    await context.dispatch('setIsUpdatingLayer', false);
  }
}

export function setIsSwipeVisible(context, value) {
  context.commit('setIsSwipeVisible', value);
}

export async function loadGatherApps(context) {
  const gatherApps = await Api.loadGatherApps();
  context.commit('setGatherApps', gatherApps);
}
export async function setSidebarWidth(context, value) {
  context.commit('setSidebarWidth', value);
}

export async function setLayerSearchQuery(context, value) {
  context.commit('setLayerSearchQuery', value);
}

export async function setTempFigureStylingRules(
  context,
  value: FigureStylingRule[]
) {
  context.commit('setTempFigureStylingRules', value);
}

export async function addNewFigureStylingRule(
  context,
  newFigureStylingRule: FigureStylingRule
) {
  context.commit('addNewFigureStylingRule', newFigureStylingRule);
}

export async function updateOneOfTempFigureStylingRules(
  context,
  payload: UpdateOneOfFigureStylingRulesPayload
) {
  context.commit('updateOneOfTempFigureStylingRules', payload);
}

export async function deleteOneOfTempFigureStylingRules(
  context,
  locator: FigureStylingRuleLocator
) {
  context.commit('deleteOneOfTempFigureStylingRules', locator);
}

export async function setActiveTempFigureStylingRuleLocator(
  context,
  value: FigureStylingRuleLocator | undefined
) {
  context.commit('setActiveTempFigureStylingRuleLocator', value);
}

export async function modifySelectedFigureStylingRules(
  context,
  selectedFigureStylingRules: FigureStylingRule[]
) {
  const nextFigureStylingRules = context.state.figureStylingRules.filter(
    (fsr) => fsr.figureId !== context.state.selectedFigure.id
  );
  nextFigureStylingRules.push(...selectedFigureStylingRules);
  context.commit('setFigureStylingRules', nextFigureStylingRules);
  context.commit(
    'setTempFigureStylingRules',
    context.getters.createTempFigureStylingRules()
  );
}

export async function setIsReloadNeeded(context, value) {
  context.commit('setIsReloadNeeded', value);
}

export async function updateFigureSettings(
  context,
  payload: UpdateFigureSettingsPayload
) {
  context.commit('updateFigureSettings', payload);
}

export async function setFigureExportingAction(context, value) {
  context.commit('setFigureExportingAction', value);
}

export async function setIsLoadingSamples(context, value) {
  context.commit('setIsLoadingSamples', value);
}

export async function setHasTooManySamplesInViewportBySampleGroupId(
  context,
  value
) {
  context.commit('setHasTooManySamplesInViewportBySampleGroupId', value);
}

export async function setIsLayerReorderingNeeded(context, value) {
  context.commit('setIsLayerReorderingNeeded', value);
}

export async function setIsSyncingFigureViewState(context, value) {
  context.commit('setIsSyncingFigureViewState', value);
}

export async function setCachedValue(
  context,
  cv: { path: string; value: any }
) {
  context.commit('setCachedValue', cv);
}
