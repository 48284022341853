import type { Sample, RawObject } from './definitions';

type RawSample = RawObject<Sample>;

export function normalizeSampleLocation(sample: RawSample): RawSample {
  let { longitude, latitude } = sample;
  if (typeof longitude === 'string') {
    longitude = parseFloat(longitude);
  }
  if (typeof latitude === 'string') {
    latitude = parseFloat(latitude);
  }
  return {
    ...sample,
    longitude,
    latitude,
  };
}

export function normalizeSampleLabelPosition(sample: RawSample): RawSample {
  const { label_position } = sample;
  if (typeof label_position === 'object') {
    return sample;
  }
  try {
    return {
      ...sample,
      label_position: JSON.parse(label_position),
    };
  } catch (e) {
    console.warn(
      `[warn]: The label position of the sample ${sample.id} is illegal.`
    );
    return {
      ...sample,
      label_position: null,
    };
  }
}
