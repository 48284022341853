const getDashboardPrerequisites = () => {
  return axios.get('/dashboard/prerequisites');
};

const getHydrocarbonOptions = (data) => {
  return axios.get('/process/hydrocarbon-options', {
    params: data,
  });
};

const updateHydrocarbonField = (data) => {
  return axios.post('/process/hydrocarbon-field', data);
};

const updateProjectAssessed = (data) => {
  return axios.put('/process', data);
};

const getAllSamples = (data) => {
  return axios.get('/data/samples', {
    params: data,
  });
};

const getOnboardingSettings = () => {
  return axios.get('/process/onboarding-settings');
};

const getCustomHierarchy = (data) => {
  return axios.get('/process/custom-hierarchy', {
    params: data,
  });
};

const completeOnboarding = (data) => {
  return axios.post('/process/complete-onboarding', data);
};

const createScenarios = (data) => {
  return axios.post('/process/scenarios', data);
};

const processScenario = (id, factor) => {
  return axios.post(`/process/scenarios/${id}/process`, {
    factor,
  });
};

const duplicateScenario = (id) => {
  return axios.post(`/process/scenarios/${id}/duplicate`);
};

const deleteScenario = (id) => {
  return axios.delete('/process/scenarios/' + id);
};

const toggleFavourite = () => {
  return axios.post('/process/favourite/toggle');
};

const getFavourites = () => {
  return axios.get('/process/favourite');
};

const getFavourite = (id) => {
  return axios.get(`/process/favourite/${id}`);
};

const importFavourite = (id, data) => {
  return axios.post(`/process/favourite/${id}`, data);
};

const updateDashboardView = (view) => {
  return axios.post('/project/update-by-field', {
    field: 'dashboard_view',
    value: view,
  });
};

/** Data */
const downloadTable = (data) => {
  return axios.get('/download/gather-table', {
    params: data,
    responseType: 'blob',
  });
};

const addSampleChemical = (data) => {
  return axios.post('/data/chemical/create-many', data);
};

const getExcelBulkExport = (selected_fields) => {
  return axios.get('/data/excel-bulk-export', {
    params: {
      selected_fields,
    },
    responseType: 'blob',
  });
};

const uploadExcelBulkExport = ({ data, progressUpdater }) => {
  return axios.post('/data/excel-bulk-export', data, {
    onUploadProgress: (progressEvent) =>
      progressUpdater(
        parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
      ),
  });
};

/**
 * Scenario Group Management
 */
const createScenarioGroup = (data) => {
  return axios.post('/dashboard/scenario-group', data);
};

const updateScenarioGroup = (group_id, data) => {
  return axios.put('/dashboard/scenario-group/' + group_id, data);
};

const duplicateScenarioGroup = (group_id) => {
  return axios.post(`/dashboard/scenario-group/${group_id}/duplicate`);
};

const toggleScenario = (group_id, scenario_id) => {
  return axios.post(
    `/dashboard/scenario-group/${group_id}/toggle-scenario/${scenario_id}`
  );
};

const setAllOutdatedStatistics = () => {
  return axios.put(`/dashboard/scenario-group/all-statistics-outdated`);
};

const setAllFigureLayersOutdated = () => {
  return axios.put(`/dashboard/scenario-group/all-figure-layers-outdated`);
};

/**
 * Scenario Styling
 */
const updateScenarioStyling = (set_id, scenario) => {
  return axios.put(`/dashboard/scenario/${set_id}/scenario-styling`, {
    scenario,
  });
};

const cancelUploads = () => {
  return axios.post(`/upload/cancel`);
};

/**
 * Company profile
 */
const getCompanyProfiles = () => {
  return axios.get(`/company/chemical-profile`);
};

export default {
  getDashboardPrerequisites,
  getHydrocarbonOptions,
  updateHydrocarbonField,
  getAllSamples,
  updateProjectAssessed,

  getOnboardingSettings,
  getCustomHierarchy,
  completeOnboarding,

  toggleFavourite,
  getFavourites,
  getFavourite,
  importFavourite,
  updateDashboardView,

  createScenarios,
  processScenario,
  duplicateScenario,
  deleteScenario,

  downloadTable,
  addSampleChemical,
  getExcelBulkExport,
  uploadExcelBulkExport,

  createScenarioGroup,
  updateScenarioGroup,
  duplicateScenarioGroup,
  toggleScenario,
  setAllOutdatedStatistics,
  setAllFigureLayersOutdated,
  updateScenarioStyling,

  cancelUploads,

  getCompanyProfiles,
};
