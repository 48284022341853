<template>
  <div>
    <slot
      :isLoading="isLoading"
      :hasSimpleLoader="hasSimpleLoader"
      :errorLoadingTable="errorLoadingTable"
      :editable="editable"
      :hasNoSamples="hasNoSamples"
      :samples="samples"
      :tabId="tabId"
      :getFilters="getFilters"
      :loadingSamples="loadingSamples"
      :loadingTemplate="loadingTemplate"
      :loadSamples="loadSamples"
      :loadSamplesFromFilters="loadSamplesFromFilters"
      :setSearchQuery="setSearchQuery"
      :inputValues="inputValues"
      :sections="sections"
      :editingField="editingField"
      :editingSample="editingSample"
      :selectField="selectField"
      :toggleSection="toggleSection"
      :setImageUrl="setImageUrl"
      :viewMedia="viewMedia"
      :templateTab="templateTab"
      :subFolders="subFolders"
      :updateSample="updateSample"
      :deleteSample="deleteSample"
      :onSubFolderChanged="onSubFolderChanged"
      :imageUrl="imageUrl"
      :getImageURL="getImageURL"
      :mediaType="mediaType"
      :mediaSrc="mediaSrc"
      :closeMediaModal="closeMediaModal"
      :updateField="updateField"
      :tableSkeletonConfig="tableSkeletonConfig"
    >
      <TableView
        v-if="templateTab"
        :isLoading="isLoading"
        :hasSimpleLoader="hasSimpleLoader"
        :errorLoadingTable="errorLoadingTable"
        :editable="editable"
        :hasNoSamples="hasNoSamples"
        :samples="samples"
        :tabId="tabId"
        :getFilters="getFilters"
        :loadingSamples="loadingSamples"
        :loadingTemplate="loadingTemplate"
        :loadSamples="loadSamples"
        :loadSamplesFromFilters="loadSamplesFromFilters"
        :setSearchQuery="setSearchQuery"
        :inputValues="inputValues"
        :sections="sections"
        :editingField="editingField"
        :editingSample="editingSample"
        :selectField="selectField"
        :toggleSection="toggleSection"
        :setImageUrl="setImageUrl"
        :viewMedia="viewMedia"
        :templateTab="templateTab"
        :subFolders="subFolders"
        :updateSample="updateSample"
        :deleteSample="deleteSample"
        :onSubFolderChanged="onSubFolderChanged"
        :imageUrl="imageUrl"
        :getImageURL="getImageURL"
        :mediaType="mediaType"
        :mediaSrc="mediaSrc"
        :closeMediaModal="closeMediaModal"
        :updateField="updateField"
        :tableSkeletonConfig="tableSkeletonConfig"
        :loadEverything="reloadTable"
      />
    </slot>
  </div>
</template>

<script>
import { parseIdAsArray } from '@component-library/business-logic/app';
import { useTaskStore } from '@component-library/store/tasks';
import _debounce from 'lodash/debounce';
import { mapStores } from 'pinia';
import { mapActions, mapState } from 'vuex';
import usePopoverHandler from '../gather-table-editor/composables/usePopoverHandler';
import api from './api';
import TableView from './components/TableView.vue';
import { getDefaultFilters } from './store';
import EventBus from '@component-library/EventBus';

export default {
  name: 'GatherTable',
  components: {
    TableView,
  },
  props: {
    tabId: [Number, String],
    editable: {
      type: Boolean,
      default: true,
    },
    filter: Object,
    hasSimpleLoader: Boolean,
    tableSkeletonConfig: Object,
  },
  setup() {
    const { isReadOnly } = usePopoverHandler();
    return { isReadOnly };
  },
  data: () => ({
    loadingSamples: true,
    samples: {},
    editingSample: null,
    editingField: null,

    searchQuery: null,
    hasSearched: false,

    loadingTemplate: false,
    templateTab: null,
    subFolders: [],
    sections: [],
    inputValues: [],

    errorLoadingTable: false,

    imageUrl: null,
    mediaSrc: null,
    mediaType: null,
  }),
  watch: {
    async tabId() {
      this.reloadTable();
    },
    filter: {
      handler: function (route) {
        if (this.isLoading) {
          return;
        }

        this.reloadTable();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapStores(useTaskStore),
    ...mapState({
      project: (state) => state.project,
      filters: (state) => state.gather.filters,
    }),
    getImageURL() {
      if (!this.imageUrl) {
        return;
      }

      if (this.imageUrl.includes('base64')) {
        return this.imageUrl;
      }

      if (Array.isArray(this.imageUrl)) return this.imageUrl;

      return (
        '/api/images/value/' + this.project.project_id + '/' + this.imageUrl
      );
    },
    hasNoSamples() {
      return (
        this.samples.data &&
        this.samples.data.length === 0 &&
        !this.searchQuery &&
        !this.hasSearched
      );
    },
    isLoading() {
      return (
        (this.loadingSamples && !this.samples.data) ||
        this.loadingTemplate ||
        (this.sections.filter((s) => s.loading).length > 0 &&
          this.sections.filter((s) => s.loaded).length == 0)
      );
    },
    getFilters() {
      return this.filter
        ? { ...getDefaultFilters(), ...this.filter }
        : this.filters;
    },
    isCompoundApp() {
      return parseIdAsArray(this.tabId).length > 1;
    },
  },
  methods: {
    ...mapActions({
      setFilters: 'gather/setFilters',
    }),
    async loadSamples() {
      this.editingSample = null;
      this.loadingSamples = true;

      try {
        const { data } = await api.getSamples({
          tab_id: this.tabId,
          page: this.samples ? this.samples.current_page : 1,
          search: this.searchQuery,
          filters: this.getFilters,
        });

        this.samples = data.samples;

        if (
          this.sections.length > 0 &&
          this.sections.filter((s) => s.toggled).length == 0
        ) {
          this.toggleSection(this.sections[0]);
        } else {
          this.sections
            .filter((s) => s.toggled)
            .forEach((section) => {
              this.getInputValuesBySection(section);
            });
        }

        this.loadingSamples = false;
        this.$emit('samplesLoaded', { target: this });

        try {
          this.taskStore.completeTask('collect-gather-data');
        } catch (e) {}
      } catch (e) {
        this.loadingSamples = false;
        this.errorLoadingTable = true;
        throw e;
      }
    },
    loadSamplesFromFilters() {
      this.hasSearched = true;
      this.loadSamples();
    },
    async loadFullTemplate() {
      this.editingSample = null;
      this.loadingTemplate = true;

      try {
        if (!this.isCompoundApp) {
          const { data } = await api.getTemplate({
            tab_id: this.tabId,
            filters: this.filter ? this.getFilters : null,
          });

          const { template: tab } = data;
          this.templateTab = tab;
          this.subFolders = tab.sub_folders;
          this.sections = tab.sections.map((s) => {
            return {
              ...s,
              max_section_index: parseInt(s.max_section_index),
              toggled: false,
              loading: false,
              loaded: false,
            };
          });

          this.setFilters(tab.filters);
        } else {
          const appIds = parseIdAsArray(this.tabId);
          this.templateTab = await api.getCompoundApp(appIds);
          this.subFolders = [];
          this.sections = this.templateTab.sections.map((section) => {
            return {
              ...section,
              toggled: false,
              loading: false,
              loaded: false,
            };
          });
        }
      } catch (e) {
        this.errorLoadingTable = true;
        throw e;
      } finally {
        this.loadingTemplate = false;
      }
    },
    clearSearch() {
      this.searchQuery = null;
      this.loadSamples();
    },
    selectSample(sample) {
      this.editingSample = sample;
      this.editingField = null;
    },
    selectField({ sample, field_id, section_index }) {
      this.editingSample = sample;
      this.editingField = { field_id, section_index };
    },
    updateSample({ sample, inputValues }) {
      let sampleToUpdate = this.samples.data.find((s) => s.id == sample.id);
      sampleToUpdate.custom_title = sample.custom_title;

      this.inputValues = Object.freeze([
        ...this.inputValues.filter((s) => s.sample_id != sample.id),
        ...inputValues.filter((s) => s.sample_id == sample.id),
      ]);
    },
    deleteSample(id) {
      const sampleIndex = this.samples.data.findIndex((s) => s.id == id);
      if (sampleIndex != -1) {
        this.samples.data.splice(sampleIndex, 1);
        this.inputValues = Object.freeze(
          this.inputValues.filter((iV) => iV.sample_id != id)
        );
      }
    },
    onSubFolderChanged(value) {
      this.editingSample.sub_folder = value;
    },
    async reloadTable() {
      this.errorLoadingTable = false;
      this.subFolders = [];
      this.sections = [];
      this.samples = {};

      await this.loadFullTemplate();
      await this.loadSamples();
    },
    async getInputValuesBySection(section) {
      const sampleIds = this.samples.data.map((s) => s.id);
      if (sampleIds.length == 0) {
        return;
      }

      section.loading = true;

      try {
        const inputValues = await api.getInputValuesBySection({
          tab_id: this.tabId,
          section_id: section.id,
          sample_ids: sampleIds,
        });

        this.inputValues = Object.freeze([
          ...this.inputValues.filter(
            (iV) => iV.template_section_id != section.id
          ),
          ...inputValues,
        ]);

        section.loaded = true;
      } catch (e) {
        this.errorLoadingTable = true;
        throw e;
      } finally {
        section.loading = false;
      }
    },
    toggleSection(section) {
      section.toggled = !section.toggled;

      if (section.toggled) {
        this.getInputValuesBySection(section);
      } else {
        this.inputValues = Object.freeze(
          this.inputValues.filter((iV) => iV.template_section_id != section.id)
        );
      }
    },
    setSearchQuery: _debounce(function (value) {
      this.hasSearched = true;
      this.searchQuery = value;

      this.loadSamples();
    }, 300),
    closeMediaModal() {
      this.mediaSrc = null;
      this.mediaType = null;
    },
    viewMedia({ src, type }) {
      if (!this.isReadOnly(this.tabId)) {
        return;
      }

      if (src instanceof Blob) {
        this.mediaSrc = URL.createObjectURL(src);
      } else {
        this.mediaSrc =
          '/api/images/value/' + this.project.project_id + '/' + src;
      }
      this.mediaType = type;
    },
    setImageUrl(value) {
      if (!this.isReadOnly(this.tabId) && this.editable) {
        return;
      }

      this.imageUrl = value;
    },
    async updateField(fieldId, prop, value) {
      for (let i = 0; i < this.templateTab.sections.length; i++) {
        const s = this.templateTab.sections[i];
        for (let j = 0; j < s.template_fields.length; j++) {
          const f = s.template_fields[j];
          if (f.id === fieldId) {
            const nextField = { ...f, [prop]: value };
            const { data } = await axios.put(
              `gather/template/field/${fieldId}`,
              {
                template_section_id: nextField.template_section_id,
                field_type_id: nextField.field_type_id,
                system_reference: nextField.system_reference,
                label: nextField.label,
                is_required: nextField.is_required,
                is_permanent: nextField.is_permanent,
                options: nextField.options,
                order: nextField.order,
                c_template_field_id: nextField.c_template_field_id,
                c_input_value: nextField.c_input_value,
              }
            );
            nextField.created_at = data.result.created_at;
            nextField.updated_at = data.result.updated_at;
            s.template_fields.splice(j, 1, nextField);
          }
        }
      }
    },
  },
  mounted() {
    this.reloadTable();

    EventBus.$on('resetTable', () => {
      this.reloadTable();
    });
  },
  beforeUnmount() {
    EventBus.$off('resetTable');
  },
};
</script>
