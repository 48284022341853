<script setup>
import Paginate from '@/js/components/Pagination.vue';
import ChemistryTableSkeleton from '@/js/components/placeholders/ChemistryTableSkeleton.vue';
import ImageModal from '@component-library/components/ImageModal.vue';
import MediaModal from '@component-library/components/MediaModal.vue';
import MoveableEditBox from '@/js/components/MoveableEditBox.vue';
import FieldPane from '../../gather-table-editor/FieldPane.vue';
import ItemPane from '../../gather-table-editor/ItemPane.vue';
import usePopoverHandler from '../../gather-table-editor/composables/usePopoverHandler';
import TableFilter from '../../gather-table-filter/index.vue';
import TableContainer from './TableContainer.vue';
import Spinner from '@component-library/components/Spinner.vue';

const { popoverData, itemPopoverData } = usePopoverHandler();

const props = defineProps({
  isLoading: Boolean,
  hasSimpleLoader: Boolean,
  errorLoadingTable: Boolean,
  editable: Boolean,
  hasNoSamples: Boolean,
  samples: Object,
  tabId: [Number, String],
  getFilters: Object,
  loadingSamples: Boolean,
  loadingTemplate: Boolean,
  loadSamples: Function,
  loadSamplesFromFilters: Function,
  setSearchQuery: Function,
  loadEverything: Function,
  inputValues: Array,
  sections: Array,
  editingField: Object,
  selectField: Function,
  toggleSection: Function,
  setImageUrl: Function,
  viewMedia: Function,
  templateTab: Object,
  subFolders: Array,
  updateSample: Function,
  deleteSample: Function,
  onSubFolderChanged: Function,
  imageUrl: [String, Array],
  getImageURL: [String, Array],
  mediaType: String,
  mediaSrc: String,
  closeMediaModal: Function,
  updateField: Function,
  tableSkeletonConfig: Object,
});

const updateInputValues = (updatedValues) => {
  let newValues = JSON.parse(JSON.stringify(props.inputValues));

  updatedValues.forEach((inputValue) => {
    const index = newValues.findIndex((iv) => iv.id === inputValue.id);
    if (index !== -1) {
      Object.keys(inputValue).forEach((key) => {
        newValues[index][key] = inputValue[key];
      });
    } else {
      newValues.push(inputValue);
    }
  });

  props.updateSample({
    sample: popoverData.value.sample,
    inputValues: newValues,
  });
};

const itemUpdated = (item) => {
  props.updateSample({ sample: item, inputValues: props.inputValues });
};
</script>

<template>
  <div>
    <div v-if="editable || (isLoading && !hasSimpleLoader)" class="card">
      <div class="card-body">
        <span
          v-if="isLoading"
          class="placeholder placeholder-wave w-100"
          style="height: 30px"
        />
        <TableFilter
          v-else
          :tabId="tabId"
          :editable="editable"
          :filters="getFilters"
          :loadingSamples="loadingSamples"
          :loadingTemplate="loadingTemplate"
          :isHorizontal="false"
          @paginateSamples="loadSamples"
          @loadSamples="loadSamplesFromFilters"
          @setSearchQuery="setSearchQuery"
          @loadEverything="loadEverything"
        >
          <Paginate
            class="fw-bold mt-2 mt-md-0"
            :isLoading="loadingSamples"
            :pagination="samples"
            :offset="10"
            @paginate="loadSamples"
          />
        </TableFilter>
      </div>
    </div>

    <template v-if="!editable">
      <Spinner v-if="isLoading && !hasSimpleLoader" small />
      <ChemistryTableSkeleton
        v-else-if="isLoading && !hasSimpleLoader"
        v-bind="tableSkeletonConfig"
      />
      <TableContainer
        v-else
        :samples="samples?.data ?? []"
        :inputValues="inputValues"
        :templateTab="templateTab"
        :sections="sections"
        :loadingSamples="loadingSamples"
        :editable="editable"
        @selectField="selectField"
        @toggleSection="toggleSection"
        @loadSamples="loadSamples"
        @viewImage="setImageUrl"
        @viewMedia="viewMedia"
      />
    </template>
    <template v-else>
      <div
        v-if="!isLoading && errorLoadingTable"
        class="d-flex align-items-center"
        :class="{
          'card-header bg-danger text-white font-weight-bold': editable,
          'justify-content-center h-100': !editable,
        }"
      >
        There was an unexpected error loading the table, please refresh and try
        again.
      </div>

      <div v-else class="position-relative">
        <div class="animate-table">
          <ChemistryTableSkeleton
            v-if="isLoading"
            v-bind="tableSkeletonConfig"
          />
          <div
            v-else-if="hasNoSamples && !getFilters?.updated"
            class="d-flex align-items-center justify-content-center flex-column text-center all-data-loading"
          >
            <h1 class="fal fa-exclamation-triangle"></h1>
            <h3 class="fw-bolder">No items were found for this app.</h3>
            <h6 class="text-muted mb-0">
              Please use the Gather collection tool to create these.
            </h6>
          </div>
          <TableContainer
            v-else
            :samples="samples?.data ?? []"
            :inputValues="inputValues"
            :templateTab="templateTab"
            :sections="sections"
            :editable="editable"
            @selectField="selectField"
            @toggleSection="toggleSection"
            @loadSamples="loadSamples"
            @viewImage="setImageUrl"
            @viewMedia="viewMedia"
          />
        </div>

        <MoveableEditBox v-if="popoverData || itemPopoverData">
          <template v-slot:default="{ setIsDisabled }">
            <FieldPane
              v-if="popoverData"
              :popoverData="popoverData"
              @close="popoverData = null"
              @updateInputValues="updateInputValues"
            />
            <ItemPane
              v-if="itemPopoverData"
              :sample="itemPopoverData"
              @close="itemPopoverData = null"
              @itemUpdated="itemUpdated"
              @itemDeleted="deleteSample"
              @onToggleShowDeleteModal="setIsDisabled"
            />
          </template>
        </MoveableEditBox>
      </div>
    </template>

    <ImageModal
      v-if="imageUrl"
      :imageUrl="getImageURL"
      @close="setImageUrl(null)"
    />

    <MediaModal
      v-if="mediaType"
      :src="mediaSrc"
      :type="mediaType"
      @close="closeMediaModal"
    />
  </div>
</template>

<style lang="scss">
.animate-table {
  z-index: 2;
  overflow: auto;
}

.col-lg-4.position-manager,
.col-xl-3.position-manager {
  position: absolute !important;
  right: 0;
}
</style>
