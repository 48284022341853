import { FigureExportingAction } from '../business-logic/exporting';
import { FigureSettings } from '../business-logic/figure';
import { LAYER_TYPES } from '../business-logic/layer';
import {
  DEFAULT_FIGURE_LAYOUT_ID,
  FigureLayout,
  Orientation,
} from '../business-logic/layout';
import { MediumType } from '../business-logic/medium';
import type {
  FigureStylingRule,
  FigureStylingRuleLocator,
} from '../business-logic/styling/figure-styling-rule';
import * as openlayers from '../modules/openlayers';

export const getDefaultFigureLayout = () => {
  return {
    id: DEFAULT_FIGURE_LAYOUT_ID,
    orientation: Orientation.Landscape,
    mediumType: MediumType.Screen,
  } as FigureLayout;
};

export default () => ({
  countries: {
    NZ: {
      name: 'New Zealand',
      regions: [
        { key: null, title: 'All' },
        { key: 'new_zealand', title: 'New Zealand' },
        { key: 'auckland', title: 'Auckland' },
        { key: 'bay_of_plenty', title: 'Bay Of Plenty' },
        { key: 'canterbury', title: 'Canterbury' },
        { key: 'clutha', title: 'Clutha' },
        { key: 'dunedin', title: 'Dunedin' },
        { key: 'gisborne', title: 'Gisborne' },
        { key: 'hawkes_bay', title: "Hawke's Bay" },
        { key: 'horizons', title: 'Horizons' },
        { key: 'kapiti_coast', title: 'Kāpiti Coast' },
        { key: 'manawatu_whanganui', title: 'Manawatū-Whanganui' },
        { key: 'marlborough', title: 'Marlborough' },
        { key: 'nelson', title: 'Nelson' },
        { key: 'northland', title: 'Northland' },
        { key: 'otago', title: 'Otago' },
        { key: 'queenstown', title: 'Queenstown' },
        { key: 'rotorua', title: 'Rotorua' },
        { key: 'southland', title: 'Southland' },
        { key: 'tasman', title: 'Tasman' },
        { key: 'taranaki', title: 'Taranaki' },
        { key: 'taupo', title: 'Taupo' },
        { key: 'tauranga', title: 'Tauranga' },
        { key: 'waikato', title: 'Waikato' },
        { key: 'wellington', title: 'Wellington' },
        { key: 'west_coast', title: 'West Coast' },
        { key: 'whangarei', title: 'Whangārei' },
      ],
    },
    AU: {
      name: 'Australia',
      regions: [
        { key: null, title: 'All' },
        { key: 'australia', title: 'Australia' },
        { key: 'new_south_wales', title: 'New South Wales' },
        { key: 'northern_territory', title: 'Northern Territory' },
        { key: 'queensland', title: 'Queensland' },
        { key: 'south_australia', title: 'South Australia' },
        { key: 'victoria', title: 'Victoria' },
        { key: 'western_australia', title: 'Western Australia' },
      ],
    },
    US: {
      name: 'United States',
      regions: [
        { key: null, title: 'All' },
        { key: 'united_states', title: 'United States' },
        { key: 'california', title: 'California' },
        { key: 'nevada', title: 'Nevada' },
        { key: 'reno', title: 'Reno', parentKey: 'nevada' },
      ],
    },
    GB: {
      name: 'United Kingdom',
      regions: [
        { key: null, title: 'All' },
        { key: 'united_kingdom', title: 'United Kingdom' },
        { key: 'bristol', title: 'Bristol' },
        { key: 'london', title: 'London' },
      ],
    },
    CA: {
      name: 'Canada',
      regions: [
        { key: null, title: 'All' },
        { key: 'canada', title: 'Canada' },
        { key: 'alberta', title: 'Alberta' },
        { key: 'british_columbia', title: 'British Columbia' },
        { key: 'manitoba', title: 'Manitoba' },
        { key: 'ontario', title: 'Ontario' },
        { key: 'toronto', title: 'Toronto' },
        { key: 'quebec', title: 'Quebec' },
      ],
    },
  },

  featureTypes: {
    rectangle: {
      names: {
        singular: 'rectangle',
        plural: 'rectangles',
      },
    },
    circle: {
      names: {
        singular: 'circle',
        plural: 'circles',
      },
    },
    polygon: {
      names: {
        singular: 'polygon',
        plural: 'polygons',
      },
    },
    marker: {
      names: {
        singular: 'sample',
        plural: 'samples',
      },
    },
    polyline: {
      names: {
        singular: 'line',
        plural: 'lines',
      },
    },
    arrow: {
      names: {
        singular: 'arrow',
        plural: 'arrows',
      },
    },
    siteboundary: {
      names: {
        singular: 'boundary',
        plural: 'boundaries',
      },
    },
    textarea: {
      names: {
        singular: 'text',
        plural: 'texts',
      },
    },
    'call-out': {
      names: {
        singular: 'call-out',
        plural: 'call-outs',
      },
    },
  },

  bufferableLayerTypes: [
    LAYER_TYPES.RECTANGLE,
    LAYER_TYPES.CIRCLE,
    LAYER_TYPES.POLYGON,
    LAYER_TYPES.POLYLINE,
    LAYER_TYPES.ARROW,
    LAYER_TYPES.SITE_BOUNDARY,
  ],

  hasLoadedMapData: false,

  isLoadingLayers: false,
  allLayers: [],
  isLoadingSamples: false,
  // All point samples created in Maps and Gather.
  allSamples: [],
  // All Line Or Polygon samples created in Gather.
  lopSampleByLayerId: {},
  isLoadingFigures: false,
  allFigures: [],

  layerSearchQuery: '',
  highlightedLayerId: null,

  allScenarios: [],
  allScenarioStyles: [],
  scenarioSet: null,

  globalSettings: null,

  allChemicals: [],
  allChemicalGroups: [],
  allExceedances: [],

  isUpdatingLayer: false,
  _isUpdatingLayerCounter: 0,

  shapeProperties: {
    color: '#2980B9',
    fillStyle: 0,
    outlineStyle: 0,
  },
  previousSampleProperties: null,

  esriToken: null,
  integrations: [],
  selectedFigure: null,

  figureIdToSelect: null,

  basemapRevisionDate: null,

  // Use by Visual Reporter
  selectedLayer: null,

  // used for display meta data of service layer
  selectedServiceLayerFeature: null,

  // Indicates whether a new feature is being added.  It is turned on
  // when adding a feature. It is turned off when either the feature is
  // saved or cancelled. It is used to:
  // 1. Control the availability of the Add Multiple Features switch
  // in the EditorStylePane.
  // 2. Control whether adding feature is continued.
  isAddingFeature: false,
  // This switch can be controlled in the EditorStylePane when a feature
  // is being added. By toggling this switch on, the newly added feature
  // is saved instead of being opened for editing. Then users can start
  // to create another new feature.
  addMultipleFeaturesEnabled: false,
  // Features ids in a Add Multiple Features session. The features can be
  // squares, circles, polygons, samples, lines, arrows, boudaries and texts.
  amfSessionFeatureIds: [],
  // 1: Cancel the pending feature.
  // 2: Cancel the pending feature and the newly added ones.
  amfSessionCancelChoice: 1,
  // The data used to invoke a confirm modal in the MapEditor.
  confirmData: null,
  serviceLayerVisibleExtentOption:
    openlayers.services.VISIBLE_EXTENT_OPTIONS.FULL_EXTENT,
  figurePrintLayouts: [],
  imageEditMethodCode:
    openlayers.interactions.image.constants.EDIT_METHOD_CODES
      .MOVE_RESIZE_ROTATE,
  // The samples which are being deleted should not be renderred.
  sampleIdsBeingDeleted: [],
  selectedSampleScopeId: null,
  sampleBeingRenamed: null,
  // Used to indicate whether to compare two Nearmap basemaps
  isSwipeVisible: false,
  gatherApps: [],
  sidebarWidth: 300,

  figureStylingRules: [] as FigureStylingRule[],
  // Used for the editing of styling rules
  tempFigureStylingRules: [] as FigureStylingRule[],
  activeTempFigureStylingRuleLocator: undefined as
    | FigureStylingRuleLocator
    | undefined,
  // Sometimes a reload after an operation is needed.
  // For example, after input values changed in Maps, Maps needs to
  // be reloaded to get latest input values for styling.
  isReloadNeeded: false,
  figureSettings: {
    layout: getDefaultFigureLayout(),
  } as FigureSettings,
  figureExportingAction: null as FigureExportingAction | null,
  maxEditableSampleCountPerSampleGroup: 100,
  hasTooManySamplesInViewportBySampleGroupId: {},
  // After a layer in the Recycle Bin is restored the order of layers in the Layer tab
  // needs to be adjusted.
  isLayerReorderingNeeded: false,
  isSyncingFigureViewState: false,
  cache: {
    isRenderableNonSpatialSampleGroup: {},
  },
});
